import React from "react"

const PrivacyPolicy = () => (
  <div className="privacy-policy">
    <div className="wrapper">
      <h1>Privacy policy</h1>

      <p>
        This privacy policy sets out how Hearing Birdsong uses and protects any
        information that you provide when you use{" "}
        <a href="https://beta.hearingbirdsong.com">
          https://beta.hearingbirdsong.com
        </a>
        .
      </p>

      <p>
        Hearing Birdsong is committed to ensuring that your privacy is
        protected. Should we ask you to provide certain information by which you
        can be identified when using this product, then you can be assured that
        it will only be used in accordance with this privacy statement.
      </p>
      <p>
        Hearing Birdsong may change this policy from time to time by updating
        this page. You should check this page from time to time to ensure that
        you are happy with any changes. This policy is effective from 14/6/2022.
      </p>

      <h3>How we use cookies</h3>
      <p>
        Cookies allow web applications to record activity. The web application
        can tailor its operations to your needs, likes and dislikes by gathering
        and remembering information about your anonymous use.{" "}
      </p>

      <h3>Analytics</h3>
      <p>
        We use Google Analytics to identify how this product performs. This
        helps us analyse data about traffic and improve our product in order to
        tailor it to customer needs. We only use this information for
        statistical analysis purposes.
      </p>
      <p>
        Overall, cookies help us provide you with a better website by enabling
        us to monitor which pages you find useful and which you do not. This
        analytics cookie in no way gives us access to your computer or any
        personal information about you.
      </p>
      <p>
        Most web browsers automatically accept cookies, but you can usually
        modify your browser setting to decline cookies if you prefer. This may
        prevent you from taking full advantage of the website.
      </p>

      <h3>What we collect</h3>
      <p>
        Within Hearing Birdsong, we may provide you the opportunity of
        submitting feedback about your experience. We may collect the following
        information:
      </p>
      <ul>
        <li>name and job title</li>
        <li>contact information including email address</li>
        <li>
          demographic information such as postcode, preferences and interests
        </li>
        <li>other information relevant to your experience</li>
        <li>Information that you choose to provide</li>
      </ul>
      <p>
        We require this information to better understand your needs and provide
        you with an improved service.
      </p>

      <h3>Internal record keeping</h3>
      <ul>
        <li>
          We may use the information you provide to improve our products and
          services
        </li>
        <li>
          We may periodically send promotional emails about news, new products,
          campaigns, special offers or other information which we think you may
          find interesting using the email address which you have provided.
        </li>
        <li>
          From time to time, we may also use your information to contact you for
          market research purposes.
        </li>
        <li>
          We may contact you by email, phone, fax or mail. We may use the
          information to customise the website according to your interests.
        </li>
      </ul>
      <h3>Security</h3>
      <p>
        We are committed to ensuring that your information is secure. In order
        to prevent unauthorised access or disclosure, we have put in place
        suitable physical, electronic and managerial procedures to safeguard and
        secure the information we collect online.
      </p>
      <h3>Links to other websites</h3>
      <p>
        Our website may contain links to other websites of interest. However,
        once you have used these links to leave our product, you should note
        that we do not have any control over that other website. Therefore, we
        cannot be responsible for the protection and privacy of any information
        which you provide whilst visiting such sites and such sites are not
        governed by this privacy statement. You should exercise caution and look
        at the privacy statement applicable to the website in question.
      </p>
      <h3>Controlling your personal information</h3>
      <p>
        You may choose to restrict the collection or use of your personal
        information in the following ways:
      </p>
      <p>
        if you have previously agreed to us using your personal information for
        direct marketing purposes, you may change your mind at any time by
        writing to or emailing us at{" "}
        <a href="mailto:hello@hearingbirdsong.com">hello@hearingbirdsong.com</a>
      </p>
      <p>
        We will not sell, distribute or lease your personal information to third
        parties unless we have your permission or are required by law to do so.
        We may use your personal information to send you promotional information
        about third parties which we think you may find interesting if you tell
        us that you wish this to happen.
      </p>
      <p>
        You may request details of personal information which we hold about you
        under the Data Protection Act 1998. A small fee will be payable. If you
        would like a copy of the information held on you please write to Kennedy
        Woods, Unit 3.2, Yellow Stairs, Bussey Building, Peckham, London SE15
        3SN.
      </p>
      <p>
        If you believe that any information we are holding on you is incorrect
        or incomplete, please write to or email us as soon as possible at the
        above address. We will promptly correct any information found to be
        incorrect.
      </p>
    </div>
  </div>
)

export default PrivacyPolicy
